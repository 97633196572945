// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// require("chartkick")
// require("chart.js")

// var stripe = Stripe('pk_test_gl1WWSDzh53dbAM6e65lqOk2');
// var elements = stripe.elements();

// var card = elements.create('card', {
//   iconStyle: 'solid',
//   style: {
//     base: {
//       iconColor: '#8898AA',
//       padding: '5px',
//       color: 'black',
//       lineHeight: '24px',
//       fontWeight: 300,
//       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//       fontSize: '20px',
//       '::placeholder': {
//         color: '#8898AA',
//       },
//     },
//     invalid: {
//       iconColor: '#e85746',
//       color: '#e85746',
//     }
//   },
//   classes: {
//     focus: 'is-focused',
//     empty: 'is-empty',
//   },
// });

// console.log(card)
// // Add an instance of the card UI component into the `card-element` <div>
// let cardInstance = $("#card")

// if (cardInstance == null){
//   card.mount('#card-element');
// }


// function stripeTokenHandler(token) {
//   // Insert the token ID into the form so it gets submitted to the server
//   var form = document.getElementById('payment-form');
//   var hiddenInput = document.createElement('input');
//   hiddenInput.setAttribute('type', 'hidden');
//   hiddenInput.setAttribute('name', 'stripeToken');
//   hiddenInput.setAttribute('value', token.id);
//   form.appendChild(hiddenInput);

//   // Submit the form
//   form.submit();
// }

// function createToken() {
//   stripe.createToken(card).then(function(result) {
//     if (result.error) {
//       // Inform the user if there was an error
//       var errorElement = document.getElementById('card-errors');
//       errorElement.textContent = result.error.message;
//     } else {
//       // Send the token to your server
//       stripeTokenHandler(result.token);
//     }
//   });
// };

// // Create a token when the form is submitted.
// let form = document.getElementById('payment-form');

// if (form != null){
//   form.addEventListener('submit', function(e) {
//     e.preventDefault();
//     createToken();
//   });

//   card.addEventListener('change', function(event) {
//     var displayError = document.getElementById('card-errors');
//     if (event.error) {
//       displayError.textContent = event.error.message;
//     } else {
//       displayError.textContent = '';
//     }
//   });
// }
require("trix")
require("@rails/actiontext")
require("@rails/activestorage").start()